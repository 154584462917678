import { motion } from "framer-motion";
import {
    listFade_3,
    sectionFade_3,
    itemFade,
    skillstools,
} from "../components/Animations";

export default function SkillsTools() {
    return (
        <motion.div
            variants={skillstools}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.7 }}
            className="flex mx-auto mt-36 flex-col sm:flex-row gap-12 mb-36"
        >
            <div className="basis-1/2">
                <motion.div
                    variants={sectionFade_3}
                    className="text-3xl text-zinc-900 mb-8 font-Syne font-semibold"
                >
                    Skills
                </motion.div>
                <motion.ul
                    variants={listFade_3}
                    className="text-lg text-zinc-800 list-none ml-0"
                >
                    <motion.li variants={itemFade}>A/B Testing</motion.li>
                    <motion.li variants={itemFade}>
                        Competitive Analysis
                    </motion.li>
                    <motion.li variants={itemFade}>Design Systems</motion.li>
                    <motion.li variants={itemFade}>Design Thinking</motion.li>
                    <motion.li variants={itemFade}>
                        Information Architecture
                    </motion.li>
                    <motion.li variants={itemFade}>Product Design</motion.li>
                    <motion.li variants={itemFade}>
                        Wireframing & Prototyping
                    </motion.li>
                    <motion.li variants={itemFade}>User Flows</motion.li>
                </motion.ul>
            </div>
            <div className="basis-1/2">
                <motion.div
                    variants={sectionFade_3}
                    className="text-3xl text-zinc-900 mb-8 font-Syne font-semibold"
                >
                    Tools
                </motion.div>
                <motion.ul
                    variants={listFade_3}
                    className="text-lg text-zinc-800 list-none ml-0"
                >
                    <motion.li variants={itemFade}>Figma</motion.li>
                    <motion.li variants={itemFade}>Framer</motion.li>
                    <motion.li variants={itemFade}>Jira</motion.li>
                    <motion.li variants={itemFade}>Loom</motion.li>
                    <motion.li variants={itemFade}>Usertesting</motion.li>
                    <motion.li variants={itemFade}>HTML/CSS</motion.li>
                    <motion.li variants={itemFade}>Sketch</motion.li>
                    <motion.li variants={itemFade}>Photoshop</motion.li>
                </motion.ul>
            </div>
        </motion.div>
    );
}

// Accessibility - A/B Testing - Competitive Analysis - Design Systems - Design Thinking - Information Architecture - Mobile App Design - Prototyping - Visual Design - Wireframing - User Flows
