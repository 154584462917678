export const Icon = ({ src, alt }) => {
    return (
        <div className="flex md:justify-start justify-center w-full h-[100px]">
            <img
                src={src}
                alt={alt}
                className="md:w-1/2 w-[100px] h-[100px] object-contain"
            />
        </div>
    );
};
