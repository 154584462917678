export let Fade = {
    show: {
        opacity: 1,
        transition: {
            ease: "easeIn",
            duration: 0.4,
            delay: 0.1,
        },
    },
    hidden: {
        opacity: 0,
    },
};

export const FooterFade = {
    show: {
        opacity: 1,
        transition: {
            ease: "easeIn",
            duration: 0.4,
            delay: 2,
        },
    },
    hidden: {
        opacity: 0,
    },
};

export const projectEaseIn = {
    show: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            ease: "easeIn",
            duration: 0.9,
            delay: 0.7,
        },
    },
    hidden: {
        opacity: 0,
        y: 5,
    },
};

export let main_containerFade = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "easeIn",
            delayChildren: 0.3,
            staggerChildren: 0.6,
        },
    },
};

export let sectionFade = {
    show: {
        opacity: 1,
        y: 0,
    },
    hidden: {
        opacity: 0,
        y: 5,
    },
};

export let sectionFade_ProjectGallery = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            duration: 3,
            delayChildren: 1.2,
            staggerChildren: 0.2,
        },
    },
};

export let introductionFade = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            duration: 3,
            delayChildren: 0.4,
            staggerChildren: 0.3,
        },
    },
};

export let corevalues = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            delayChildren: 0.6,
            staggerChildren: 0.2,
        },
    },
};

export let headerFade_2 = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            delayChildren: 0.4,
            staggerChildren: 0.6,
        },
    },
};

export let itemFade_2 = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            delayChildren: 0.6,
            staggerChildren: 0.1,
        },
    },
};

export let skillstools = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            delayChildren: 0.2,
            staggerChildren: 0.5,
        },
    },
};

export let sectionFade_3 = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            bounce: 0,
            delayChildren: 0.3,
            staggerChildren: 0.5,
        },
    },
};

export let listFade_3 = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            // duration: 2.4,
            // delayChildren: .4,
            staggerChildren: 0.1,
            bounce: 0,
        },
    },
};

export let sectionheaderFade_4 = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            duration: 1.2,
            bounce: 0,
        },
    },
};

export let sectionFade_4 = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            duration: 4.8,
            delayChildren: 2.4,
            staggerChildren: 0.3,
            bounce: 0,
        },
    },
};

export let container = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
        transition: {
            type: "spring",
            ease: "linear",
            delayChildren: 0.3,
            staggerChildren: 0.2,
        },
    },
};

export let itemFade = {
    show: {
        opacity: 1,
        y: 0,
    },
    hidden: {
        opacity: 0,
        y: 5,
    },
};
