import { ProjectHeader } from "../../components/ProjectHeader";
import { ProjectBackground } from "../../components/ProjectBackground";
import { ProjectLayout } from "../../components/ProjectLayout";
import { ProjectSection } from "../../components/ProjectSection";

//images
import patternHero from "../../assets/patternHero.png";
import beginning from "../../assets/pattern_image.png";
import image1 from "../../assets/pattern_image1.png";
import image2 from "../../assets/pattern_image2.png";
import image3 from "../../assets/pattern_image3.png";
import highlevel from "../../assets/pattern_high_level.png";
import addkeeparchive from "../../assets/pattern_akd.png";
import nomenclature from "../../assets/pattern_CMSnomenclature.png";
import mov from "../../assets/patternMOV.png";
import misc from "../../assets/patternMISC.png";
// import image4 from "../../assets/pattern_image4.png";
import { Icon } from "../../components/Icon";
import { Caption } from "../../components/Caption";
import { Column } from "../../components/Column";
import { Content } from "../../components/Content";

export const Pattern = () => {
    return (
        <ProjectLayout>
            {/* <!-- Header --> */}

            <ProjectHeader
                img={patternHero}
                title="Optimizing For Design Efficiency"
                subtitle="Pattern Library - Design System"
            />

            {/* <!-- Background --> */}
            <ProjectBackground
                // title="Overview"
                context="Chevrolet had reached out to inquire about ways to incorporate design thinking in their products and processes. One of the initiatives that were discussed was building a pattern library to help."
                role="I led strategy and facilitated workshops, working closely with creative and authoring leadership to develop a solution that aligned with and supported their goals."
            />
            <ProjectSection>
                <Content className="w-full bg-zinc-100 p-6 rounded-md outline-dotted">
                    <Column>
                        <h3 className="">Outcome Preview</h3>
                        <p>
                            Successful rollout that improved the overall time to market for 50+ vehicle overview and supplemental pages.
                        </p>
                    </Column>
                </Content>
            </ProjectSection>

            {/* <!-- Background --> */}
            <ProjectSection>
                <div className="">
                    <Content className="">
                        <h2 className="">Getting Started</h2>
                        <div className="w-full md:w-3/4">
                            <p className="md:col-span-4 col-span-6">
                                I was responsible for developing a strategy to
                                improve the current process for publishing page experiences. The existing process had been
                                in place for over 8+ years, and despite creative and
                                authoring teams collaborate closely, they
                                experienced issues.
                            </p>
                            {/* <img
                                src={beginning}
                                alt="beginning"
                                className="invisible md:visible md:col-span-1 col-span-1"
                            /> */}
                        </div>
                    </Content>
                </div>
                <Content>
                    <h3 className="">What wasn't working</h3>
                    <p className="w-full md:w-3/4">
                        Both teams had a great foundation, which included a solid design system, but not everything went smoothly. This was evident when building out annually updated model overview (MOV) pages. They faced
                        the following:</p>
                    <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-x-12 gap-4">
                        <Column>
                            <Icon src={image1} alt="icon-1" />
                            <p className="font-medium">Continuity issues</p>
                            <p>
                                Differences between design compositions and
                                what’s authored were commonly found.
                            </p>
                        </Column>
                        <Column>
                            <Icon src={image2} alt="icon-2" />
                            <p className="font-medium">Miscommunication</p>
                            <p>
                                With too many cooks, things can get lost in the
                                cracks and become larger issues down
                                the road.
                            </p>
                        </Column>
                        <Column>
                            <Icon src={image3} alt="icon-3" />
                            <p className="font-medium">Reactive approach</p>
                            <p>
                                When issues occur, they escalated fast and
                                often required halting progress.
                            </p>
                        </Column>
                    </div>
                </Content>

                <Content>
                    {/* <h2 className="text-blue-600">Drafting the plan</h2> */}
                    <h3 className="">Making it actionable</h3>
                    <p>
                        Having a problem statement allows us to be more
                        objective in our approach.
                    </p>
                    <div className="bg-blue-100 rounded-md my-4 flex justify-start p-6 gap-x-4 items-center">
                        <p className="mt-[-3px] text-2xl">🎯</p>
                        <p className="font-semibold text-base">
                            How might we improve an existing authoring
                            process to be more efficient and scalable?
                        </p>
                    </div>
                </Content>

                <Content>
                    <h3 className="">Drafting the plan</h3>
                    <p className="md:w-3/4 w-full" >Prior to drafting up a plan I met with team members to understand how they currently work, what they hope to achieve, and any concerns they may have related to timelines and work capacity. This way I could better articulate how the process would make it easier for them.</p>
                    <img
                        src={highlevel}
                        alt="A high-level flow of how we plan to tackle this problem"
                    />
                    <Caption>
                        A high-level flow of how we plan to tackle this
                        problem
                    </Caption>
                </Content>

            </ProjectSection>

            {/* Following The Plan */}
            <ProjectSection>
                <Content>
                    <h2 className="">Following the plan</h2>
                    <h3 className="">Deciding what to add, keep, archive</h3>
                    <p className="md:w-3/4 min-w-full">
                        I proposed a series of questions that would help us
                        determine if patterns should be added to the library.
                    </p>
                    <div>
                        <img
                            src={addkeeparchive}
                            alt="A decision flow of whether we add, keep, or archive a pattern in the pattern library"
                        />
                        <Caption>
                            A decision flow of whether we add, keep, or archive
                            a pattern in the pattern library
                        </Caption>
                    </div>
                </Content>
                <Content>
                    <h3 className="">Finding common ground</h3>
                    <p className="w-full md:w-1/2">
                        After deciding how we handle a pattern, we looked at finding an appropriate label. Here's what was considered:
                    </p>
                    <ul>
                        <li>
                            When designers and authors discuss designs, they need a {" "}
                            <span className="font-semibold">common name</span>{" "} they can refer patterns as
                        </li>
                        <li>
                            Authors are {" "}
                            <span className="font-semibold">
                                visually limited
                                - </span>{" "} within the Adobe AEM directory, so naming in the way that is easily identifiable when read is important
                        </li>
                    </ul>
                    <div>
                        <img
                            src={nomenclature}
                            alt="Nomenclature Structure MOV Patterns"
                            className="border "
                        />
                        <Caption>
                            Example of the nomenclature structure used
                        </Caption>
                    </div>
                </Content>
                <Content>
                    <h3 className="">Challenges we navigated</h3>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 gap-4">
                        <Column>
                            <p className="font-medium">Approval dependencies</p>
                            <p>
                                Despite having a plan ready for execution, we faced
                                delays when it came to getting final sign-off for
                                patterns. Adjusting priority was a common occurence.
                            </p>
                        </Column>
                        <Column>
                            <p className="font-medium">Technology available</p>
                            <p>
                                Though the CMS used was able to achieve most of what
                                we proposed to build, there were cases where
                                something as simple as modifying imagery padding required workarounds.
                            </p>
                        </Column>
                    </div>
                </Content>
            </ProjectSection>

            {/* The Outcome */}
            <ProjectSection className="mb-16">
                <Content>
                    <h2 className="">Outcome</h2>
                    <img
                        src={mov}
                        alt="Nomenclature Structure MOV Patterns"
                        className=""
                    />
                    <img
                        src={misc}
                        alt="Nomenclature Structure MOV Patterns"
                        className=""
                    />
                    <h3 className="">How we succeeded</h3>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-x-12 gap-6 md:w-full">
                        <Column>
                            <p className="font-medium">Building trust</p>
                            <p>
                                Being open and transparent about our time
                                allocation helped us gauge our expectations for
                                each other.
                            </p>
                        </Column>
                        <Column>
                            <p className="font-medium">Specialized and flexible</p>
                            <p>
                                Each member specialized in an area of the project and could be relied on when additional support
                                was needed.
                            </p>
                        </Column>
                        <Column>
                            <p className="font-medium">Meeting agendas</p>
                            <p>
                                By setting weekly agenda, we came prepared and that allowed for
                                meaningful discussion.
                            </p>
                        </Column>
                    </div>
                </Content>
                <div className="md:w-3/4 w-full gap-x-12 gap-4">
                    {/* <img
                        src={image4}
                        alt="trophy icon"
                        className="md:visible md:col-span-auto"
                    /> */}
                    <Content className="cols-span-6 md:col-span-2">
                        <h3 className="">We made an impact</h3>
                        <p>
                            What initially started as a process for building a
                            pattern library for Chevrolet evolved into
                            influencing how other brands structure their
                            libraries and nomenclature within the CMS.{" "}
                        </p>
                        <p>
                            Brands like Cadillac built out their library and they reached out
                            to me for context on what worked previously and if
                            there were opportunities for improvement.
                        </p>
                    </Content>
                </div>
                <Content className="md:w-3/4 w-full mb-16">
                    <h3 className="">Next steps</h3>
                    <p>
                        As part of a design system, this library will continue
                        to be an iterative part of the design system. As new
                        business needs shift, what is built and is archived will
                        inevitably evolve over time.
                    </p>
                </Content>
            </ProjectSection>
        </ProjectLayout >
    );
};
