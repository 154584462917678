export const Column = ({ children, className }) => (
    <div
        className={
            className
                ? `flex flex-col gap-1 ${className}`
                : "flex flex-col gap-1"
        }
    >
        {children}
    </div>
);
