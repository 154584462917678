import { ProjectHeader } from "../../components/ProjectHeader";
import { ProjectBackground } from "../../components/ProjectBackground";
import { ProjectLayout } from "../../components/ProjectLayout";
import { ProjectSection } from "../../components/ProjectSection";

//images
import avenueHero from "../../assets/avenueHero.png";
import avenueProcess from "../../assets/avenue_process.png";
import challenges1 from "../../assets/avenue_challenges1.png";
import challenges2 from "../../assets/avenue_challenges2.png";
import addressproblem1 from "../../assets/avenue_addressproblem1.png";
import addressproblem2 from "../../assets/avenue_addressproblem2.png";
import addressproblem3 from "../../assets/avenue_addressproblem3.png";
import brainstorm from "../../assets/avenue_brainstorm.png";
import lofi from "../../assets/avenue_lofi.png";
import solution1 from "../../assets/avenue_solution2.png";
import solution2 from "../../assets/avenue_solution2-1.png";
import targetuser from "../../assets/avenue_targetuser.png";
import { Icon } from "../../components/Icon";
import { Caption } from "../../components/Caption";
import { Column } from "../../components/Column";
import { Content } from "../../components/Content";
import { CursorClickIcon, MapIcon, BeakerIcon, AdjustmentsIcon, ShieldCheckIcon, CashIcon, FlagIcon, SearchIcon, DatabaseIcon, LockClosedIcon, ClockIcon, ZoomOutIcon, ExclamationIcon, CodeIcon, UserGroupIcon, TrendingUpIcon, CheckCircleIcon, ScaleIcon } from '@heroicons/react/outline';


export const Avenue = () => {
    return (
        <ProjectLayout>
            {/* <!-- Header --> */}
            <ProjectHeader
                img={avenueHero}
                title="Encouraging Alternative Transport"
                subtitle="Avenue - Mobile App Concept"
            />

            <ProjectBackground
                // title="Overview"
                context="This project was part of a design challenge that looked at ways to promote alternative transport and its benefits."
                role="I had 48 hours to complete this challenge, and used the resources I had to build towards the final solution."
            />

            {/* <!-- Overview --> */}
            <ProjectSection>
                <h2>Overview</h2>

                <Content>
                    <h3>Challenge</h3>
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 grids-cols-3">
                        <p>
                            How might we design an experience that educates,
                            encourages and enables individuals to leverage
                            alternative means of transportation when traveling
                            within a city or surrounding boroughs?
                        </p>
                        {/* <div>
                                    <p>Why this challenge?</p>
                                    <ul>
                                        <li>
                                            Get to learn about commuting in a
                                            city
                                        </li>
                                        <li>My location</li>
                                    </ul>
                                </div> */}
                    </div>
                </Content>
                <Content>
                    <h3>Drafting a plan</h3>
                    {/* <img src={avenueProcess} alt="process" /> */}
                    <div className="grid md:grid-cols-3 grid-cols-1 md:gap-x-12 gap-6 md:w-full w-2/3">
                        <Column>
                            <SearchIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-semibold text-blue-600">Stage 1</p>
                            <p>For Who? What problem? Why is it the way it is?</p>
                        </Column>
                        <Column>
                            <BeakerIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-semibold text-orange-600">Stage 2</p>
                            <p>What I’ve learned so far? How can I approach this?</p>
                        </Column>
                        <Column>
                            <FlagIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-semibold text-green-600">Stage 3</p>
                            <p>How might this be achieved? The outcome?</p>
                        </Column>
                    </div>
                </Content>
            </ProjectSection>

            {/* <!-- Stage 1 --> */}
            <ProjectSection>
                <Content>
                    <h2 className="text-blue-600">Stage 1</h2>
                    <h3 className="text-blue-800">Challenge goals</h3>
                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-12 gap-6">
                        <Column>
                            {/* <Icon className="pb-8" src={challenges1} alt="col-1" /> */}
                            <p className="font-semibold">
                                Encourage usage - primary goal
                            </p>
                            <p>What's getting in the way? Are they any pain points that we aren't aware of?</p>
                        </Column>
                        <Column>
                            {/* <Icon className="pb-8" src={challenges2} alt="col-2" /> */}
                            <p className="font-semibold">Educate riders</p>
                            <p>Can this address the stigma? If so, how?</p>
                        </Column>
                    </div>
                    <blockquote>
                        By educating riders, can we increase usage? Can learning promote usage?
                    </blockquote>
                </Content>

                <Content>
                    <div className="grid md:grid-cols-2 sm:grid-cols-1  md:gap-x-12 gap-6">
                        <Column>
                            <h4>Who would this be for and what can we assume?</h4>
                            <p>
                                For the sake of time, I figured it’d be easier
                                to choose a problem I could relate with. Having
                                an accessible demographic was key in helping me
                                decide a target user.
                            </p>
                            <p>College Students who...</p>
                            <ul>
                                <li>have no access to a vehicle (money)</li>
                                <li>are new to a city (like Chicago)</li>
                                <li>predominantly low income</li>
                                <li>is in charge of external schooling cost</li>
                            </ul>
                        </Column>
                        <Column>
                            <Icon className="pb-8" src={targetuser} alt="target user" />
                        </Column>
                    </div>
                </Content>

                <Content>
                    <h3 className="text-blue-800">Research</h3>
                    <Content className="mb-8">
                        <h4>What we're trying to learning?</h4>
                        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-12 gap-6 md:w-full w-full">
                            <Column>
                                <p className="font-semibold">
                                    The current experience
                                </p>
                                <p>
                                    Learning about the current scenario would help us
                                    understand how people interact with alternative transportation.
                                </p>
                            </Column>
                            <Column>
                                <p className="font-semibold">
                                    The problem at hand
                                </p>
                                <p>
                                    Understanding who the target demographic would be was essential, because of my limited familarity with alternative transportation.
                                </p>
                            </Column>
                        </div>
                    </Content>
                    <Content className="mb-8">
                        <h4>Competitive analysis</h4>
                        <div className="overflow-x-auto">
                            <table className="table-auto border rounded-sm ">
                                <thead className="border">
                                    <tr className="border">
                                        <th className="text-left border p-2">
                                            Name
                                        </th>
                                        <th className="text-left border p-2">
                                            Purpose
                                        </th>
                                        <th className="text-left border p-2">
                                            Benefits
                                        </th>
                                        <th className="text-left border p-2">
                                            Drawbacks
                                        </th>
                                        <th className="text-left border p-2">
                                            Method
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border">
                                        <td className="border p-2">Transit</td>
                                        <td className="border p-2">
                                            Get a person to a destination on
                                            time
                                        </td>
                                        <td className="border p-2">
                                            Primarily focused on train/bus
                                            commuting (public transport)
                                        </td>
                                        <td className="border p-2">
                                            Comparing option cost
                                        </td>
                                        <td className="border p-2">Mobile</td>
                                    </tr>
                                    <tr className="border">
                                        <td className="border p-2">
                                            Citymapper
                                        </td>
                                        <td className="border p-2">
                                            Get a person to explore a
                                            destination
                                        </td>
                                        <td className="border p-2">
                                            <ul className="list-dash">
                                                <li>
                                                    Ability to choose your own
                                                    commute
                                                </li>
                                                <li>Planning trips</li>
                                                <li>Showing approx. cost</li>
                                            </ul>
                                        </td>
                                        <td className="border p-2">
                                            Comparing option cost
                                        </td>
                                        <td className="border p-2">
                                            Mobile & Desktop
                                        </td>
                                    </tr>
                                    <tr className="border">
                                        <td className="border p-2">Moovit</td>
                                        <td className="border p-2">
                                            Get a person from point A to point B
                                        </td>
                                        <td className="border p-2">
                                            Simple menu (directions, stations,
                                            and lines)
                                        </td>
                                        <td className="border p-2">
                                            <ul className="list-dash">
                                                <li>Planning trips</li>
                                                <li>
                                                    Lacks bike & scooter sharing
                                                </li>
                                            </ul>
                                        </td>
                                        <td className="border p-2">
                                            Mobile & Desktop
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <div>
                                <h4>
                                    Notes
                                </h4>
                                <p className="font-medium">
                                    Design Opportunities
                                </p> */}
                        {/* TODO: put in toggle */}
                        {/* <ul>
                                    <li>
                                        Cater to the booking experience (moving?
                                        need for a trip? on a budget?)
                                        <ul className="list-dash">
                                            <li>
                                                Provide suggestions (don't
                                                stagnate user success)
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        How has the user impacted the
                                        environment?
                                        <ul className="list-dash">
                                            <li>
                                                UI affirmation to signal
                                                progress
                                            </li>
                                            <li>
                                                Did they save time or money?
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Affordability and convenience is a
                                        factor
                                    </li>
                                    <li>
                                        Categories? (List of Errands, Moving to
                                        a new place, Grocery Shopping)
                                    </li>
                                </ul>
                                <p className="font-medium ">
                                    Necessary UI Requirements
                                </p>
                                <ul>
                                    <li>Cost to book</li>
                                    <li>
                                        Time to get to destination
                                    </li>
                                    <li>Alerts/Updates</li>
                                    <li>
                                        Profile → Statistics (Time saved? Money
                                        saved?)
                                    </li>
                                </ul>
                            </div> */}
                    </Content>
                    <Content>
                        <h4>Stakeholder interviews</h4>
                        <div className="grid md:grid-cols-12 grid-cols-1 md:gap-x-12 gap-6 ">
                            <p className="col-span-12">
                                I conducted 3 brief interviews to see if I can
                                get a glimpse into how commuters view public
                                transport.
                            </p>
                            <Column>
                                {/* <p>Interview Constraints</p>
                                    <ul>
                                        <li>Time</li>
                                        <li>
                                            Convenience Sample (via mutual
                                            friends)
                                        </li>
                                        <li>
                                            Geographic (limited due to 3
                                            interviews)
                                        </li>
                                    </ul> */}
                            </Column>
                        </div>
                        <div className="grid md:grid-cols-3 grid-cols-1 md:gap-x-12 gap-6">
                            <Column>
                                <p className="font-semibold">Perception with cost</p>
                                <blockquote className="mb-8 md:mb-0">
                                    "I used Uber to get to work, but I stopped
                                    because I noticed it was breaking the bank"{" "}
                                </blockquote>
                            </Column>
                            <Column>
                                <p className="font-semibold">Ownership & control</p>
                                <blockquote className="mb-8 md:mb-0">
                                    "I prefer driving because I have peace of
                                    mind being able to move freely on my own."
                                </blockquote>
                            </Column>
                            <Column>
                                <p className="font-semibold">Pure necessity</p>
                                <blockquote className="mb-8 md:mb-0">
                                    “I don’t really take public transport
                                    because I want to, but because I have to”
                                </blockquote>
                            </Column>
                        </div>
                    </Content>
                </Content>
            </ProjectSection>

            {/* <!-- Stage 2 --> */}
            <ProjectSection>
                <Content>
                    <h2 className="text-orange-600">Stage 2</h2>
                    <h3 className="text-orange-800">
                        What did the research tell us?
                    </h3>
                    <Content className="mb-8 md:mb-0">
                        <h4>Finding the problem</h4>
                        <p>
                            Based on the research I conducted I noticed it's the
                            perception of alt. transportation that dissuades
                            usage.
                        </p>

                        {/* <p className="font-semibold">
                                    Factors above are centered around clarity.
                                </p> */}
                        {/* <p>
                                    These are valid concerns, so how do we
                                    address these concerns?
                                </p>
                                <p>
                                    Could we allow flexibility to choose
                                    alternative transportation methods base on
                                    preferences?
                                </p> */}
                        <div className="grid md:grid-cols-3 grid-cols-1 md:gap-x-12 gap-6 md:w-full w-full">
                            <Column>
                                <CashIcon className="h-6 w-6 text-zinc-800" />
                                <p className="font-semibold">Cost</p>
                                <p>Knowing whether they're saving money in the long term</p>
                            </Column>
                            <Column>
                                <ShieldCheckIcon className="h-6 w-6 text-zinc-800" />
                                <p className="font-semibold">Trust</p>
                                <p>
                                    Reliance on knowing they'll be able to be from point A to point B
                                </p>
                            </Column>
                            <Column>
                                <AdjustmentsIcon className="h-6 w-6 text-zinc-800" />
                                <p className="font-semibold">Flexibility</p>
                                <p>
                                    Ability to access at any time and any where
                                </p>
                            </Column>
                        </div>
                    </Content>
                </Content>
                <Content className="mb-8">
                    <h4>Addressing the problem</h4>
                    <p>
                        Now that we have an understanding of the problem, we
                        can reframe challenge to an actionable statement:
                    </p>
                    {/* <ul>
                                    <li>
                                        Point-of-View
                                        <ul className="list-dash">
                                            <li>
                                                Empathizing with the user
                                                (Chicago commuters)
                                            </li>
                                            <li>
                                                What they said? What they felt?
                                                What they did or didn't do?
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        How Might We... Type Questions
                                        <ul className="list-dash">
                                            <li>
                                                Types of questions are broad
                                                enough to not rule out potential
                                                approaches to this challenge
                                            </li>
                                        </ul>
                                    </li>
                                </ul> */}
                    <blockquote className="mb-8">
                        <span className="font-semibold">
                            Mission statement:{" "}
                        </span>
                        Get college students to use alternative
                        transportation methods.
                    </blockquote>
                    <p>
                        This will be achieved by aiming to focus on the
                        following:
                    </p>
                    <div className="grid md:grid-cols-3 grid-cols-1 md:gap-x-12 gap-6">
                        <Column>
                            <Icon
                                src={addressproblem1}
                                alt="address problem 1"
                            />
                            <p className="font-semibold">
                                Reaching destinations comfortably
                            </p>
                            <p>How might clarity impact the value they receive?</p>
                        </Column>
                        <Column>
                            <Icon
                                src={addressproblem2}
                                alt="address problem 2"
                            />

                            <p className="font-semibold">Embracing ownership and confidence</p>
                            <p>How might we give control on how they
                                choose to commute?</p>
                        </Column>
                        <Column>
                            <Icon
                                src={addressproblem3}
                                alt="address problem 3"
                            />
                            <p className="font-semibold">Highlighting key advantages</p>
                            <p>What might they gain from this experience?</p>
                        </Column>
                    </div>
                </Content >
                <Content>
                    <h3 className="text-orange-800">Exploration</h3>
                    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-12 gap-6">
                        <div>
                            <img src={brainstorm} alt="brainstorm" />
                            <Caption>Paper wireframe</Caption>
                            {/* <ul>
                                        <li>
                                            Purpose: Fast Iterations, idea
                                            generation
                                        </li>
                                        <li>
                                            Identify elements to include
                                             - How
                                            might we educate a student?
                                            <ul className="list-dash">
                                                <li>
                                                    Cost of each option
                                                </li>
                                                <li>
                                                    Time to destination
                                                </li>
                                                <li>
                                                    Suggested transit options
                                                    based on preferences
                                                </li>
                                            </ul>
                                        </li>
                                    </ul> */}
                        </div>
                        <div>
                            <img src={lofi} alt="lofi" />
                            <Caption>Low fidelity wireframe</Caption>
                            {/* <ul>
                                        <li>
                                            Purpose: Transition towards digital
                                        </li>
                                        <li>
                                            Purpose of each feature (refinement)
                                        </li>
                                    </ul> */}
                        </div>
                    </div>
                    <Content>
                        <h4>Design challenges</h4>
                        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-x-12 gap-6">
                            <Column>
                                <p className="font-medium">
                                    Using metrics (cost breakdowns)
                                </p>
                                <p>Metrics can be a great tool to help
                                    users learn, being able to use metrics
                                    to encourage use is a challenge.</p>
                            </Column>
                            <Column>
                                <p className="font-medium">
                                    Considering user habits
                                </p>
                                <p>A design’s Intent doesn’t alway dictate
                                    user action.</p>
                            </Column>
                        </div>
                    </Content>
                </Content>
            </ProjectSection >

            {/* <!-- Stage 3 --> */}
            < ProjectSection >
                <Content>
                    <h2 className="text-green-600">Stage 3</h2>
                    <h3 className="text-green-800">Solution</h3>
                    <div className="grid md:grid-cols-12 grid-cols-1">
                        <img
                            className="col-span-7"
                            src={solution1}
                            alt="solution1"
                        />
                        <img
                            className="col-span-5"
                            src={solution2}
                            alt="solution2"
                        />
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 md:gap-x-12 gap-6 my-6">
                        <Column>
                            <p className="font-semibold">
                                Getting started - Onboarding
                            </p>
                            <p>We might ask...</p>
                            <ul>
                                <li>Current commute</li>
                                <li>+ or - commuting experience</li>
                                <li>Preferences</li>
                            </ul>
                        </Column>
                        <Column>
                            <p className="font-semibold">
                                Go To - Usage
                            </p>
                            <p>
                                Suggested offers several recommended commuting
                                methods
                            </p>
                            <ul>
                                <li>
                                    Aid in decision making process (clarity)
                                </li>
                                <li>
                                    Types
                                    <ul className="list-dash">
                                        <li>Cheapest - Cost Effective</li>
                                        <li>Fastest - Time</li>
                                        <li>
                                            Most Relaxed - Few transfers/minimal
                                            walking
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </Column>
                        <Column>
                            <p className="font-semibold">Profile - Educate</p>
                            <ul>
                                <li>Money spent where?</li>
                                <li>
                                    How does it compare to vehicle ownership?
                                </li>
                                <li>Elements of delight: Caloric equivalents</li>
                            </ul>
                        </Column>
                    </div>
                    {/* <div>
                                <h4>
                                    Inspiration
                                </h4>
                                <ul>
                                    <li>
                                        Hopper & Hotels (Results & Offers) -
                                        user knows why an option is better
                                        (price/value)
                                    </li>
                                    <li>
                                        Waze (Sense of Control/Clarity - Toll
                                        Free Routes)
                                    </li>
                                    <li>
                                        Mint & YNAB (How metrics facilitate
                                        engagement?)
                                    </li>
                                    <li>
                                        Awareness in Spending
                                    </li>
                                </ul>
                            </div> */}
                </Content>
                <Content>
                    <h3 className="text-green-800">Reflection</h3>
                    <Content className="mb-8">
                        <h4>What went well?</h4>
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-12 gap-6">
                            <Column>
                                <p className="font-semibold">Newly developed enthusiam</p>
                                <p>
                                    My interest for transportation quickly developed after this project, where I eventually made the move to a city with great public transportation.
                                </p>
                            </Column>
                            <Column>
                                <p className="font-semibold">Choosing methods wisely</p>
                                <p>
                                    Ability to gather research with
                                    Understanding the effort level a method would take and to what degree you could execute with time constraints helped tremendously.
                                </p>
                            </Column>
                            {/* <Column>
                                <p className="font-semibold">Choose methods wisely</p>
                                <p>
                                    Gathering valuable insight on the
                                    current scenario
                                </p>
                            </Column> */}
                        </div>
                    </Content>

                    {/* <div>
                                <h4>
                                    What Didn't Go Well?
                                    </h4>
                                    <ul>
                                        <li>
                                            Choosing Approach - Open-ended
                                            Time-based Challenge - something i'm not
                                            use to (10 hrs)
                                        </li>
                                        <li>
                                            Understanding What You Can Change -
                                            Needed to take step back and broaden my
                                            approach; Example: difficult to change
                                            experience **during** a bus ride (many
                                            external factors)
                                        </li>
                                    </ul>
                            </div> */}
                    <Content className="mb-8">
                        <h4>What could have been better?</h4>
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-12 gap-6 md:w-full w-full">
                            <Column>
                                <p className="font-semibold">Focus on which populations to sample</p>
                                <p>I’d place greater emphasis on the size
                                    and geography of where the responses are
                                    coming from.</p>
                            </Column>
                            <Column>
                                <p className="font-semibold">Lacking survey participation</p>
                                <p>Since the timeframe for this project was
                                    time-based, conducting a high sample
                                    sample was seemed out of reach.</p>
                            </Column>
                        </div>
                    </Content>
                    <Content>
                        <h4>Takeaways</h4>
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-12 gap-6 md:w-full w-full">
                            <Column>
                                <p className="font-semibold">Context is king</p>
                                <p>Geographically speaking, cities are
                                    unique with how they handle transit. Cities like Chicago, IL have a deep investment in public transportation, where as cities like Boise, ID are far more reliant on cars.</p>
                            </Column>
                            <Column>
                                <p className="font-semibold">Daunting and anxiety inducing</p>
                                <p>After learning more about the current
                                    scenario, I’m more empathetic about why
                                    commuters “choose” alternative transportation.</p>
                            </Column>
                        </div>
                    </Content>
                    {/* <div>
                                <h4>
                                    What I Enjoyed
                                </h4>
                                <ul>
                                    <li>
                                        Felt Like an Experiment - Seeing where
                                        the hypothesis stands, but also deciding
                                        an approach was fun.
                                    </li>
                                    <li>
                                        Potential for the Future
                                        <ul className="list-dash">
                                            <li>
                                                App would partner with grocery
                                                stores to allow customers to
                                                earn
                                            </li>
                                            <li>
                                                Partnership with global
                                                infrastructure organizations and
                                                donate a portion of ad revenue
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div> */}
                </Content>
            </ProjectSection>
        </ProjectLayout>
    );
};
