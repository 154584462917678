import { Routes, Route, Navigate, Outlet } from "react-router-dom";
// import logo from './logo.svg';
import "./App.css";
import { auth } from "./authenticate/auth";
import About from "./pages/About";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { Avenue } from "./pages/work/avenue";
import { Pattern } from "./pages/work/pattern";
import { State } from "./pages/work/state";
import { Recall } from "./pages/work/recall";

const PrivateRoutes = () => {
    return auth.currentUser() ? <Outlet /> : <Navigate to="/login" />;
};

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<PrivateRoutes />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/avenue" element={<Avenue />} />
                    <Route path="/pattern" element={<Pattern />} />
                    <Route path="/state" element={<State />} />
                    <Route path="/recall" element={<Recall />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;
