import { motion } from "framer-motion";

import Navbar from "../components/Navbar";
import Intro from "../components/Intro";
import ProjectGallery from "../components/ProjectGallery";
import Footer from "../components/Footer";
import { useScrollToTop } from "../hooks/scrollToTop";

export default function Home() {
    useScrollToTop();

    return (
        <div className=" bg-body text-white font-Overpass font-light">
            <motion.div
                className="container mx-auto px-8 md:px-12 lg:px-56"
                initial="hidden"
                whileInView="show"
                viewport={{ once: true }}
            >
                {/* <!-- Navbar --> */}
                <Navbar />

                {/* <!-- Intro --> */}
                <Intro />

                {/* <!-- Project Gallery --> */}
                <ProjectGallery />
                {/* <!-- Footer --> */}
                <Footer />
            </motion.div>
        </div>
    );
}
