import { motion } from "framer-motion";
import { sectionFade_ProjectGallery, itemFade } from "./Animations";
import samobile_thumb from "../assets/samobile_thumb.png";
import avenue_thumb from "../assets/avenue_thumb.png";
import pattern_library_thumb from "../assets/pattern_library_thumb.png";
import recall_warranty_center_thumb from "../assets/recall_warranty_center_thumb.png";
import loghat_thumb from "../assets/loghat_thumb.png";
import { Link } from "react-router-dom";

export default function Intro() {
    return (
        <motion.div
            variants={sectionFade_ProjectGallery}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="relative z-20 container mt-16 content-start justify-center grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6"
        >
            {/* <!--Card--> */}
            <motion.div variants={itemFade}>
                <div className="group bg-blue-800 rounded-2xl overflow-hidden hover:ease-in duration-200">
                    <Link to="/state">
                        <div className="px-6 py-6">
                            <div className="text-xl text-zinc-50 group-hover:text-zinc-50 font-Syne font-semibold duration-200">
                                Building from the ground up
                            </div>
                            <p className="text-blue-100 group-hover:text-blue-100 duration-200">
                                State Auto Insurance - Mobile App
                            </p>
                        </div>
                        <img
                            className="group-hover:scale-150 w-full h-full object-cover object-top hover:ease-in duration-300 origin-top"
                            src={samobile_thumb}
                            alt="State Auto Insurance - Mobile App Thumbnail"
                        />
                    </Link>
                </div>
            </motion.div>

            {/* <!--Card--> */}
            <motion.div variants={itemFade}>
                <div className="group bg-orange-700 rounded-2xl overflow-hidden hover:ease-in duration-200">
                    <Link to="/recall">
                        <div className="px-6 py-6">
                            <div className="text-xl text-zinc-50 group-hover:text-zinc-50 font-Syne font-semibold duration-200">
                                Ensuring clarity when it matters
                            </div>
                            <p className="text-orange-100 group-hover:text-orange-100 duration-200">
                                Ownership Safety - Web Platform
                            </p>
                        </div>
                        <img
                            className="group-hover:scale-150 w-full h-full object-cover object-top hover:ease-in duration-300 origin-top"
                            src={recall_warranty_center_thumb}
                            alt="Ownership Safety - Web Platform Thumbnail"
                        />
                    </Link>
                </div>
            </motion.div>

            {/* <!--Card--> */}
            <motion.div variants={itemFade}>
                <div className="group bg-gray-600 rounded-2xl overflow-hidden hover:ease-in duration-200">
                    <Link to="/pattern">
                        <div className="px-6 py-6">
                            <div className="text-xl text-zinc-50 group-hover:text-zinc-50 font-Syne font-semibold duration-200">
                                Optimizing for design efficiency
                            </div>
                            <p className="text-gray-100 group-hover:text-gray-100 duration-200">
                                Pattern Library - Design System
                            </p>
                        </div>
                        <img
                            className="group-hover:scale-150 w-full h-full object-cover object-top hover:ease-in duration-300 origin-top"
                            src={pattern_library_thumb}
                            alt="Pattern Library - Design System Thumbnail"
                        />
                    </Link>
                </div>
            </motion.div>

            {/* <!--Card--> */}
            <motion.div variants={itemFade}>
                <div className="group bg-green-700 rounded-2xl overflow-hidden hover:ease-in duration-200">
                    <Link to="/avenue">
                        <div className="px-6 py-6">
                            <div className="text-xl text-zinc-50 group-hover:text-zinc-50 font-Syne font-semibold duration-200">
                                Encouraging alternative transport
                            </div>
                            <p className="text-green-100 group-hover:text-green-100 duration-200">
                                Avenue - Mobile App Concept
                            </p>
                        </div>
                        <img
                            className="group-hover:scale-150 w-full h-full object-cover object-top hover:ease-in duration-300 origin-top"
                            src={avenue_thumb}
                            alt="Avenue - Mobile App Concept Thumbnail"
                        />
                    </Link>
                </div>
            </motion.div>

            {/* <!--Card--> */}
            <motion.div variants={itemFade}>
                <div className="group bg-zinc-800 rounded-2xl overflow-hidden hover:ease-in border border-zinc-200 duration-200">
                    <Link to="/">
                        <div className="px-6 py-6">
                            <div className="text-xl text-zinc-400 font-Syne font-semibold duration-200">
                                Coming Soon
                            </div>
                            <p className="text-zinc-400">
                                Loghat - Prototyping Concept
                            </p>
                        </div>
                        <img
                            className="group-hover:scale-150 w-full h-full object-cover object-top hover:ease-in duration-300 origin-top"
                            src={loghat_thumb}
                            alt="Loghat Fitness App - UI Exploration Thumbnail"
                        />
                    </Link>
                </div>
            </motion.div>

            {/* <!--Card-->
            <motion.div variants={itemFade}>
                <div className="group bg-zinc-50 rounded-2xl overflow-hidden hover:ease-in duration-200 hover:bg-indigo-50">                 
                    <a href="https://okapihouse.notion.site/Insurance-Icon-Set-d34ede7437eb48ec84b38a6e9d6b66ad?pvs=4"  target="_blank">
                        <div className="px-6 py-6">
                            <div className="text-xl text-zinc-50 group-hover:text-zinc-50 font-Syne font-semibold duration-200">Establishing Recognizable Icons</div>
                            <p className="text-zinc-800 group-hover:text-zinc-50 duration-200">Iconography Set</p>
                        </div>
                        <img className="group-hover:scale-125 w-full h-full object-cover object-top hover:ease-in duration-300 origin-top" src={icons_thumb} alt="iconography Project Thumbnail" />
                    </a>
                </div>
            </motion.div> */}

            {/* <!--Card--> */}
            {/* <motion.div variants={itemFade}>
                <div className="rounded-lg overflow-hidden bg-zinc-50 hover:drop-shadow-sm hover:ease-out hover:-translate-y-.5 hover:scale-105 duration-300">
                    <a href="https://yambao.notion.site/eSign-Document-Experience-8ffd765fd519452fb5fb053cf8d198a8">
                        <img className="w-full h-64 object-cover object-center" src="https://images.unsplash.com/photo-1639753528176-fea260eda9ea?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG5by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=926&q=80" alt="Mountain" />
                        <div className="px-6 py-6">
                            <div className="text-xl text-zinc-50">eSign Document Experience</div>
                            <p className="text-zinc-800">
                            Simplifying an e-Signature Experience 
                            </p>
                        </div>
                    </a>
                </div>
                </motion.div> */}

            {/* <!--Card-->
                <motion.div variants={itemFade}>
                    <div className="rounded-lg overflow-hidden hover:drop-shadow-md hover:ease-out hover:-translate-y-3 hover:scale-105 duration-300">
                        <a href="healthopx_project.png" target="_blank">
                        <img className="w-full h-64 object-cover object-center" src={healthopx_thumb} alt="HealthOpX Project Thumbnail"/>
                        <div className="px-6 py-6 border border-x-slate-800 border-b-slate-800 border-t-transparent rounded-b-lg">
                            <div className="text-xl text-zinc-50 font-Syne font-semibold">HealthOpX Mobile MVP</div>
                            <p className="text-zinc-800">
                                Eliminating Barriers to Healthcare
                            </p>
                        </div>
                        </a>
                    </div>
                </motion.div> */}

        </motion.div>
    );
}
