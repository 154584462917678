import { motion } from "framer-motion";

import { useScrollToTop } from "../hooks/scrollToTop";
import { Fade, projectEaseIn } from "./Animations";
import Footer from "./Footer";
import NavbarLight from "./NavbarLight";

export const ProjectLayout = ({ children }) => {
    useScrollToTop();

    return (
        <motion.div
            variants={Fade}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="bg-neutral-50 text-zinc-900 font-Overpass font-light font-base"
        >
            <div className="container mx-auto px-8 md:px-12 lg:px-56">
                {/* <!-- Navbar --> */}
                <NavbarLight />
            </div>
            <div className="container mx-auto px-8 md:px-12 lg:px-56">
                <motion.div
                    variants={projectEaseIn}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true }}
                >
                    {children}
                </motion.div>
            </div>
            {/* <!-- Footer --> */}
            <Footer dark={false} />
        </motion.div>
    );
};
