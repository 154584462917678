import { motion } from "framer-motion";
import {
    container,
    introductionFade,
    item,
    itemFade,
} from "../components/Animations";
import sedona from "../assets/sedona.webp";

export default function Introduction() {
    return (
        <motion.div
            variants={introductionFade}
            className="container h-vh flex mx-auto flex-wrap lg:flex-row gap-12 my-24"
        >
            <motion.div
                variants={itemFade}
                className="text-4xl md:basis-6/12 sm:basis-full mb-4 text-zinc-900 font-Syne font-semibold"
            >
                A bit about myself
            </motion.div>
            <motion.div variants={itemFade} className="text-base sm:basis-full grid md:grid-cols-2 gap-x-12 text-zinc-800 font-Overpass font-light">
                <p className="mb-4" >I’ll be brief, I promise—I grew up playing video games for much of my youth and stumbled across Photoshop CS6, to which I instantly became attached. Throughout my adolescence, I designed for local businesses and gaming organizations, which eventually led me to pursue a UX program at Michigan State University.</p>
                <p className="mb-4">After college, I designed for State Auto, helping in their digital transformation. Most recently, I wrapped up my time at MRM, where I worked with clients like Chevrolet and General Motors, improving their web experiences. Currently, I'm finding my next adventure and taking some time to sharpen my skills.</p>
            </motion.div>
            <motion.div variants={itemFade} className=" container">
                <img
                    src={sedona}
                    className="object-cover object-center rounded-2xl w-full h-72 my-auto shadow"
                    alt="Justin overlooking the majestic landscapes of Sedona National Park."
                ></img>
            </motion.div>
        </motion.div>
    );
}
