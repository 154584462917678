import Navbar from "../components/Navbar";
import Introduction from "../components/Introduction";
import CoreValues from "../components/CoreValues";
import SkillsTools from "../components/SkillsTools";
import Interest from "../components/Interest";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { main_containerFade, sectionFade } from "../components/Animations";
import PhotoGallery from "../components/PhotoGallery";

export default function About() {
    return (
        <div className="bg-body text-zinc-900 font-Overpass font-light">
            <div className="container mx-auto px-8 md:px-12 lg:px-56">
                <header>
                    <Navbar />
                </header>

                <main>
                    <motion.div
                        variants={main_containerFade}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true }}
                    >
                        <motion.section variants={sectionFade} aria-labelledby="introduction-title" tabIndex="0">
                            <Introduction />
                        </motion.section>

                        <motion.section
                            variants={sectionFade}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true, amount: 0.7 }}
                            aria-labelledby="core-values-title"
                            tabIndex="0"
                        >
                            <CoreValues />
                        </motion.section>

                        <section aria-label="Photo Gallery" tabIndex="0">
                            <PhotoGallery />
                        </section>

                        <section aria-labelledby="skills-tools-title" tabIndex="0">
                            <SkillsTools />
                        </section>

                        <section aria-labelledby="interests-title" tabIndex="0">
                            <Interest />
                        </section>
                    </motion.div>
                </main>

                <Footer />
            </div>
        </div>
    );
}