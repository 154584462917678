import { ProjectHeader } from "../../components/ProjectHeader";
import { ProjectLayout } from "../../components/ProjectLayout";
import { ProjectSection } from "../../components/ProjectSection";
import { ProjectBackground } from "../../components/ProjectBackground";

//images
import stateAutoPolicy_flow from "../../assets/state_AutoPolicy_flow.png";
import statePropertyPolicy_flow from "../../assets/state_PropertyPolicy_weather_flow.png";
import stateClaimProto from "../../assets/state_claim_optimized.gif";
import statePaymentProto from "../../assets/state_payment_optimized.gif";
import stateViewProto from "../../assets/state_view_optimized.gif";
import stateHero from "../../assets/stateHero.png";
import goal1 from "../../assets/state_Frame_603.png";
import goal2 from "../../assets/state_Frame_602.png";
import goal3 from "../../assets/state_Frame_601.png";
import process from "../../assets/state_Frame_102.png";
import companalysis from "../../assets/state_ca.png";
import statetree from "../../assets/state_tree.png";
import observation1 from "../../assets/state_Frame_600.png";
import observation2 from "../../assets/state_Frame_599.png";
import dashboard from "../../assets/state_dashboard.png";
import billing from "../../assets/state_billing.png";
import policy from "../../assets/state_policies.png";
import more from "../../assets/state_more.png";
import claim from "../../assets/state_claim.png";
import cards from "../../assets/state_Group_26.png";
import solution1 from "../../assets/state_Group_1.jpg";
import solution2 from "../../assets/state_Group_2.jpg";
import iterationMore from "../../assets/state_Group_3.png";
import solution4 from "../../assets/state_snippet.png";
import solution5 from "../../assets/state_Group_4.jpg";
import results1 from "../../assets/state_Frame_597.png";
import results2 from "../../assets/state_Frame_598.png";
import reflection1 from "../../assets/state_Frame_595.png";
import reflection2 from "../../assets/state_Frame_594.png";
import reflection3 from "../../assets/state_Frame_596.png";
import { Icon } from "../../components/Icon";
import { Caption } from "../../components/Caption";
import { Column } from "../../components/Column";
import { Content } from "../../components/Content";
import { CursorClickIcon, MapIcon, SearchIcon, DatabaseIcon, LockClosedIcon, ClockIcon, ZoomOutIcon, ExclamationIcon, CodeIcon, UserGroupIcon, TrendingUpIcon, CheckCircleIcon, ScaleIcon, KeyIcon, CursorArrowRippleIcon, SmileIcon } from '@heroicons/react/outline';

export const State = () => {
    return (
        <ProjectLayout>
            {/* <!-- Header --> */}

            <ProjectHeader
                img={stateHero}
                title="Building from the Ground Up"
                subtitle="State Auto Insurance - Mobile App"
            />
            <ProjectBackground
                // title="Overview"
                context="In late 2021, State Auto had launched their mobile app to reach customers and improve brand perception."
                role="I led the design, strategy and collaborated frequently with engineering and product to develop a solution that would strengthen State Auto’s digital footprint."
            />

            {/* <!-- Overview --> */}
            <ProjectSection>
                {/* <h2 className="">Overview</h2> */}
                {/* <Content className="md:w-3/4 w-full">
                    <h3 className="">Background</h3>
                    <p>
                        In an increasingly competitive insurance market, State Auto recognized the need to expand their digital presence. The insurance industry had undergone a shift, with customers expecting more seamless, intuitive digital experiences comparable to what they encounter in other industries, like mobile banking apps. State Auto had already taken steps to develop web products aimed at meeting customer needs, but these efforts did not yet fully delivered on customer expectations or engagement.
                    </p>
                </Content> */}
                <Content className="md:w-3/4 w-full">
                    <h3 className="">What prompted us to build a mobile app?</h3>
                    <p className="font-medium underline underline-offset-8">Competition</p>
                    <p>
                        State Auto had rapidly transformed their digital offerings over the past few years; however, they continued faced pressure from competition and customer expectations.
                    </p>
                    <p>
                        Competitor mobile app experiences were more well equipped to build and maintain relationships with their customers. Customers also weren't resonating with the brand like in the past, often moving to other competitors.
                    </p>
                </Content>
                <Content className="w-full bg-zinc-100 p-6 rounded-md outline-dotted">
                    <Column>
                        <h3 className="">Outcome Preview</h3>
                        <p>
                            Time to file a claim reduced by at least 50% attributing to reduced customer service call volumes.
                        </p>
                    </Column>
                </Content>
                {/* <Content>
                    <h3 className="">Goal</h3>
                    <div className="grid md:grid-cols-3 grids-cols-1 gap-x-12">
                        <Column>
                            <p className="font-medium">Ease of access</p>
                            <p>Can this address issues related to access?</p>
            
                        </Column>

                        <Column>
                            <p className="font-medium">Increase engagement</p>
                            <p>How can we better serve customers?</p>
               
                        </Column>

                        <Column>
                            <p className="font-medium">
                                Mitigate system strain
                            </p>
                            <p>
                                How can this product co-exist with other State
                                Auto products? - Service Design
                            </p>
    
                        </Column>
                    </div>
                </Content> */}
            </ProjectSection>

            {/* <!-- Background --> */}
            {/* <ProjectSection>
                <Content>
                    <h2 className="">Background</h2>
                    <p className="md:w-3/4 w-full">
                        The success of State Auto’s Submit a Claim Experience
                        and Customer Self-Service Portal were a driving force in
                        deciding suitable design opportunities.
                    </p>
                </Content> */}
            {/* <Content>
                    <h3 className="">Process</h3>
                    <p>Facilitated UX through several ways:</p>
                    <img src={process} alt="process" />
                </Content> */}
            {/* <Content>
                    <h3 className="">Why Mobile?</h3>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12">
                        <Column>
                            <p className="font-medium">Industry Expectations</p>
                            <p>
                                As insurance shifts to become more innovative,
                                traditional approaches like calling or a website
                                won't be enough to sustain growth in the long
                                term.
                            </p>
                        </Column>
                        <Column>
                            <p className="font-medium">Customer Engagement</p>
                            <p>
                                Aside from the desktop products and website,
                                we’re limited with the way customers interact
                                with State Auto as a brand
                            </p>
                        </Column>
                    </div>
                </Content>
            </ProjectSection> */}

            {/* <!-- Research --> */}
            <ProjectSection>
                <Content className="md:w-3/4 w-full">
                    <h2 className="">Getting started</h2>
                    <p>
                        Along with the existing products serving as a framework,
                        external research was conducted to understand the
                        industry’s expectations.
                    </p>
                </Content>
                <Content>
                    <h3 className="">Building empathy</h3>
                    <Column className="md:w-3/4 w-full">
                        <p>
                            To empathize what types of issues customers were encountering, I listened to 10 customer service calls to get in the mind of the customer.
                        </p>
                        <p>Customers expressed <span className="font-medium">frustration when filing claims and paying bills</span> and customer service representatives <span className="font-medium">gathering claim information caused delays.</span> Two main customer types were observed: those who prefer calling for claims and payments, and those who call after unsuccessful self-service attempts.</p>
                    </Column>
                </Content>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 gap-4">
                    <Column>
                        <h3 className="">What we can learn from others</h3>
                        <p>
                            By looking at industry competitors,
                            what content are they showcasing in their apps, and
                            how can we leverage some of these features in our
                            app?
                        </p>
                        <p>There were many similarities across the board for overall features. There were also some <span className="font-medium">gaps in what competitors surface to users</span> throughout the other areas of their apps.</p>
                    </Column>
                    <div>
                        <a href="#companalysis"><img src={companalysis} alt="Competitive analysis looking at different insurance competitors' mobile app offerings" className="hover:scale-105 scale-100 duration-300" /></a>

                        <a href="#_" class="lightbox" id="companalysis">
                            <img src={companalysis} alt="Enlarged view of a competitive analysis looking at different insurance competitors' mobile app offerings" />
                        </a>
                        <Caption>
                            Competitive analysis
                        </Caption>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 gap-4">

                    <Column>
                        <h3 className="">Matching the mental model</h3>
                        <p>
                            After the competitive analysis, a tree test helped
                            determine where users would expect to find common
                            insurance info.
                        </p>
                        <p>Participants generally knew where to find the different items. Some items like documents confused participants since they didn't know what kind of documents they were asked to look for.</p>
                    </Column>
                    <div>
                        <a href="#statetree"><img src={statetree} alt="Tree test conducted looking at how to inform the information architecture of the mobile app" className="hover:scale-105 scale-100 duration-300" /></a>

                        <a href="#_" class="lightbox" id="statetree">
                            <img src={statetree} alt="Enlarged view of a tree test conducted looking at how to inform the information architecture of the mobile app" />
                        </a>

                        <Caption>
                            In-app navigation tree testing
                        </Caption>
                    </div>
                </div>
            </ProjectSection>

            <ProjectSection>
                <h2 className="">Finding the problem</h2>
                <Content>
                    <h3 className="">Common themes</h3>
                    <Column className="md:w-3/4 w-full">
                        <ul>
                            <li>There's a baseline of common features across competitor apps, but there’s are also notable gaps in personalized content that could present an <span className="font-medium">opportunity for differentiation and innovation.</span></li>
                            <li>Processes for <span className="font-medium">filing a claim and making a payment are significant pain points</span> for customers, leading to frustration and inefficiency. This suggests a need for improving these core functions in the app.</li>
                            <li>While users generally understand the overall structure of an insurance app, some <span className="font-medium">terminology led to confusion</span> and potentially hindering user navigation.</li>
                        </ul>
                    </Column>
                </Content>
                <Content>
                    <h3 className="">Addressing the Problem</h3>
                    <Column className="md:w-3/4 w-full">
                        <p>Having an understanding of the big issues at play, I created an problem statement to keep the overall approach actionable:</p>
                        <Column className="bg-zinc-100 p-6 mt-4 rounded-md outline-dotted">
                            <p className="font-medium text-lg">
                                How might we create an experience that strengthens State Auto's digital footprint and improves brand perception among customers who are increasingly drawn to competitors?
                            </p>
                        </Column>
                    </Column>
                </Content>
            </ProjectSection>

            {/* Exploration & Validation */}
            <ProjectSection>
                <h2 className="">Iterating and testing</h2>
                <Content>
                    <h3 className="">Mapping the complex</h3>
                    <Column className="md:w-3/4 w-full">
                        <p>Since this project had complexities when it came to filing a claim, it was important map out the flows to understand the key differences when building the screen out for design and engineering.</p>
                    </Column>
                    <div className="grid md:grid-cols-1 grid-cols-1 gap-x-12">
                        <div className="md:col-span-1 col-span-1">
                            <Caption>Auto policy</Caption>
                            <a href="#auto_flow"><img src={stateAutoPolicy_flow} alt="User flow for submitting a claim under your auto policy" className="hover:scale-105 scale-100 duration-300" /></a>
                            <a href="#_" class="lightbox" id="auto_flow">
                                <img src={stateAutoPolicy_flow} alt="Enlarged user flow for submitting a claim under your auto policy" />
                            </a>
                            {/* <img src={dashboard} alt="icon" /> */}
                        </div>
                        <div className="md:col-span-1 col-span-1">
                            <Caption>Property policy - weather related</Caption>
                            <a href="#property_flow"><img src={statePropertyPolicy_flow} alt="User flow for submitting a weather-related claim under your property policy" className="hover:scale-105 scale-100 duration-300" /></a>
                            <a href="#_" class="lightbox" id="property_flow">
                                <img src={statePropertyPolicy_flow} alt="Enlarged user flow for submitting a weather-related claim under your property policy" />
                            </a>
                            {/* <img src={billing} alt="icon" /> */}
                        </div>
                    </div>
                </Content>
                <Content>
                    <h3 className="">Early iterations</h3>
                    <Column className="md:w-3/4 w-full">
                        <p>Starting out with low fidelity wireframes allowed for quicker exploration of options without investing too much on the visual look and feel.</p>
                    </Column>
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-x-12">
                        <div className="md:col-span-4 col-span-1">
                            <a href="#dashboard"><img src={dashboard} alt="Low fidelity exploration of the dashboard experience" className="hover:scale-105 scale-100 duration-300" /></a>
                            <a href="#_" class="lightbox" id="dashboard">
                                <img src={dashboard} alt="Enlarged view of the low fidelity exploration of the dashboard experience" />
                            </a>
                            {/* <img src={dashboard} alt="icon" /> */}
                            <Caption>Dashboard</Caption>
                        </div>
                        <div className="md:col-span-4 col-span-1">
                            <a href="#billing"><img src={billing} alt="Low fidelity exploration of the billing experience" className="hover:scale-105 scale-100 duration-300" /></a>
                            <a href="#_" class="lightbox" id="billing">
                                <img src={billing} alt="Enlarged view of the low fidelity exploration of the billing experience" />
                            </a>
                            {/* <img src={billing} alt="icon" /> */}
                            <Caption>Billing</Caption>
                        </div>
                        <div className="md:col-span-4 col-span-1">
                            <a href="#policy"><img src={policy} alt="Low fidelity exploration of the dashboard experience" className="hover:scale-105 scale-100 duration-300" /></a>
                            <a href="#_" class="lightbox" id="policy">
                                <img src={policy} alt="Enlarged view of the low fidelity exploration of the policy experience" />
                            </a>
                            {/* <img src={policy} alt="icon" /> */}
                            <Caption>Policy</Caption>
                        </div>
                        {/* <div className="md:col-span-5 col-span-1">
                            <img src={more} alt="icon" />
                            <Caption>Hi-Fi for More</Caption>
                        </div>
                        <div className="md:col-span-7 col-span-1">
                            <img src={claim} alt="icon" />
                            <Caption>Hi-Fi for Submit a Claim</Caption>
                        </div> */}
                    </div>
                </Content>
                <Content>
                    <h3 className="">Later iterations</h3>
                    <Column className="w-full">
                        <p>
                            Some visual design considerations were aligning with State Auto brand styling, and WCAG accessibility guidelines (at least AA). Since this was a native mobile app, iOS and Android guidelines and micro-interactions like button states played a big part in meeting user expectations.
                        </p>
                        <div className="py-2">
                            <a href="#iterationMore"><img src={iterationMore} alt="More (support)" className="hover:scale-105 scale-100 duration-300" /></a>
                            <a href="#_" class="lightbox" id="iterationMore">
                                <img src={iterationMore} alt="Enlarged view of More (support)" />
                            </a>
                            <Caption>
                                Evolution of More (support)
                            </Caption>
                        </div>
                        <p>Support was one of the key areas of focus for this product. A challenge that came with that is determining how we might display those avenues to support and what avenues to offer to users. One of the ways we looked to validate or invalidate our assumptions was to conduct testing.</p>
                    </Column>
                </Content>
                <Content>
                    <div className="grid md:grid-cols-1 grid-cols-1 gap-x-12">
                        <Column>
                            <h3 className="">Frequently testing hypothesis</h3>
                            <p>Opting for talk-aloud unmoderated user testing with 10 users allowed us to capture raw impressions to further understand user behavior as we explored different hypothesis. Doing this allowed us to uncover interaction flaws we had not considered, for example, when designing cards.</p>
                        </Column>
                        <div>
                            <a href="#cards"><img src={cards} alt="Example of user testing done on two different policy ID cards" className="hover:scale-105 scale-100 duration-300 mt-4" /></a>

                            <a href="#_" class="lightbox" id="cards">
                                <img src={cards} alt="Enlarged example of user testing done on two different policy ID cards" />
                            </a>
                            <Caption>
                                Example of policy ID card layouts we tested
                            </Caption>
                        </div>
                    </div>
                </Content>
                <Content>
                    <h3 className="">Challenges encountered</h3>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-x-12 gap-6 md:w-full w-full">
                        <Column>
                            <DatabaseIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Data usage</p>
                            <p>
                                Being unable to use old
                                user data properly (loyal customer base) resulted in solutions for temporary edge cases.
                            </p>
                        </Column>
                        <Column>
                            <KeyIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Legacy technology</p>
                            <p>
                                There was difficulty with outage handling, which also happened to result in no backups when submitting claims.
                            </p>
                        </Column>
                        <Column>
                            <DatabaseIcon className="h-6 w-6 text-zinc-800" />
                            <p className="font-medium">Research capabilities</p>
                            <p>
                                Limited UX
                                research resources made it
                                difficult to conduct studies at scale.
                            </p>
                        </Column>
                        {/* <Column>
                            <p className="font-medium">Healthy Pushback</p>
                            <ul>
                                <li>
                                    Knowing when to push, and when to compromise
                                </li>
                                <li>
                                    User 1st approach - avoid dark design
                                    patterns
                                </li>
                                <li>
                                    Features not solely on business wants -
                                    customization for the sake of having it
                                </li>
                            </ul>
                        </Column> */}
                    </div>
                </Content>
            </ProjectSection>

            {/* <!-- Solution --> */}
            <ProjectSection>
                <h2 className="">Where we landed</h2>

                <Content>
                    <h3 className="">Highlights</h3>
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-x-12">
                        <div className="md:md:col-span-4 col-span-1">
                            <img src={stateViewProto} alt="Prototype of viewing a policy" />
                            <Caption>Policy - View policy</Caption>
                        </div>
                        <div className="md:md:col-span-4 col-span-1">
                            <img src={statePaymentProto} alt="Prototype of making a payment" />
                            <Caption>Billing - Make a payment</Caption>
                        </div>
                        <div className="md:md:col-span-4 col-span-1">
                            <img src={stateClaimProto} alt="Prototype of submitting a claim" />
                            <Caption>Claims - Submit a claim</Caption>
                            {/* <img src={solution1} alt="icon" />
                            <Caption>Billing - Make a Payment</Caption>
                            <img src={solution3} alt="icon" />
                            <Caption>More - FAQ, Live Chat, Call Us</Caption> */}
                        </div>
                        {/* <div className="md:md:col-span-6 col-span-1">
                            <img src={solution2} alt="icon" />
                            <Caption>
                                Policy - Auto Policy - Vehicle Details
                            </Caption>
                        </div> */}
                        {/* <div className="md:md:col-span-4 col-span-1">
                            
                        </div> */}
                        {/* <div className="md:md:col-span-4 col-span-1">
                            <img src={solution4} alt="icon" />
                            <Caption>
                                Snippet From the Mobile Component Library
                            </Caption>
                        </div> */}
                        {/* <div className="md:md:col-span-12 col-span-1">
                            <img src={solution5} alt="icon" />
                            <Caption>
                                Submit a Claim - Auto - Insured Vehicle Step
                            </Caption>
                        </div> */}
                    </div>
                </Content>
                <Content>
                    <h3 className="">The impact</h3>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 gap-6">
                        <Column>
                            {/* <Icon src={results1} alt="results1" /> */}
                            {/* <ClockIcon className="h-6 w-6 text-zinc-800" /> */}
                            <p className="font-medium">Positive reception</p>
                            <p>
                                Users described the experience as <span className="font-medium bg-indigo-100 text-indigo-800">
                                    concise and
                                    accessible
                                </span>{" "}
                                when it came to how information was displayed.{" "} It was <span className="font-medium bg-indigo-100 text-indigo-800">
                                    refreshing
                                </span>{" "}
                                to see a mobile app from a 100-year-old company.
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={results2} alt="results2" /> */}
                            {/* <ClockIcon className="h-6 w-6 text-zinc-800" /> */}
                            <p className="font-medium">Saving the business time</p>
                            <p>
                                {/* What typically took{" "}
                                <span className="font-medium bg-orange-100">
                                    20 min to an hour
                                </span>{" "}
                                to file a claim by phone now takes{" "}
                                <span className="font-medium bg-orange-100">
                                    10-15 minutes
                                </span>{" "}
                                to file from within the app based on session
                                data */}
                                <span className="font-medium bg-indigo-100 text-indigo-800">Reduced time spent filing a claim by at least 50%</span> based on app session data compared to calling customer support.
                            </p>
                        </Column>
                    </div>
                </Content>
                <Content>
                    <h3 className="">My takeaways</h3>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-x-12 gap-6">
                        <Column>
                            {/* <Icon src={reflection1} alt="reflection1" /> */}

                            <p className="font-medium">Breaking it down</p>
                            <p>
                                Breaking complex processes down (like submitting a claim) to make them more digestible was a challenge.
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={reflection2} alt="reflection2" /> */}

                            <p className="font-medium">
                                Build for efficiencies
                            </p>
                            <p>
                                I migrated mobile components to the design
                                system, and I learned a ton contributing to it.
                            </p>
                        </Column>
                        <Column>
                            {/* <Icon src={reflection3} alt="reflection3" /> */}

                            <p className="font-medium">Context is key</p>
                            <p>
                                Prototypes were crucial for stakeholders to understand potential and for the engineering team to grasp intended interaction.
                            </p>
                        </Column>
                    </div>
                </Content>
                {/* <Content>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-12 md:w-3/4 w-full">
                        <Column>
                            <h3 className="">Aha Moments</h3>
                            <p>
                                A customer-facing mobile app was expressed in
                                other insurance agent interviews when asked if
                                “...customers having any other expectations from
                                State Auto”?
                            </p>
                        </Column>
                        <Column>
                            <h3 className="">Role</h3>
                            <div>
                                <p>
                                    <span className="font-medium">
                                        UX Designer -
                                    </span>{" "}
                                    Justin Yambao
                                </p>
                                <p>
                                    <span className="font-medium">
                                        UX Designer -
                                    </span>{" "}
                                    Abhishek Deshpande
                                </p>
                                <p>
                                    <span className="font-medium">
                                        Researcher -
                                    </span>{" "}
                                    Nathan Paul
                                </p>
                            </div>
                        </Column>
                    </div>
                </Content> */}
            </ProjectSection>
        </ProjectLayout >
    );
};
