import { useState } from "react";
import { Navigate } from "react-router-dom";

import { auth } from "../authenticate/auth";

export default function Login() {
    const [password, setPassword] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isIncorrect, setIsIncorrect] = useState(false);

    const handleChange = (event) => {
        setPassword(event.target.value);
    };
    const handleOnClick = () => {
        auth.login("yambao.work@gmail.com", password)
            .then((response) => {
                setIsLoggedIn(true);
            })
            .catch((error) => {
                setIsLoggedIn(false);
                setIsIncorrect(true);
            });
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            auth.login("yambao.work@gmail.com", password)
                .then((response) => {
                    setIsLoggedIn(true);
                })
                .catch((error) => {
                    setIsLoggedIn(false);
                    setIsIncorrect(true);
                    setPassword("");
                });
        }
    };

    return (
        // bg-ball bg-no-repeat bg-auto bg-top-center
        <div className=" bg-zinc-50 text-white font-Overpass font-normal">
            <div className="justify-center px-4 md:px-8 flex h-screen items-center">
                <div class="w-64">
                    {isLoggedIn && <Navigate to="/" replace={true} />}
                    <form class="pt-6 pb-8" onKeyDown={handleKeyDown}>
                        <div class="mb-2">
                            <label
                                class="block text-zinc-600 text-sm font-medium mb-2"
                                for="password"
                            >
                                Portfolio Password
                            </label>
                            {isIncorrect ? (
                                <>
                                    <input
                                        class="rounded-md appearance-none border border-red-600 text-base focus:border-red-600 w-full py-4 px-3 bg-white text-zinc-900 mb-3 focus:outline-none focus:shadow-outline placeholder:text-zinc-400"
                                        id="password"
                                        type="password"
                                        placeholder="Enter the Password"
                                        onChange={handleChange}
                                        value={password}
                                        autoFocus={true}
                                    />
                                    <span class="rounded-md text-sm text-red-500">
                                        Incorrect password. Need the password?{" "}
                                        <a href="https://www.linkedin.com/in/justinyambao/">
                                            <u>Reach out on LinkedIn.</u>
                                        </a>
                                    </span>
                                </>
                            ) : (
                                <input
                                    class="rounded-md appearance-none border border-neutral-300 text-base focus:border-zinc-600 w-full p-4 bg-white text-zinc-800 mb-2 focus:outline-none placeholder:text-zinc-500 leading-relaxed"
                                    id="password"
                                    type="password"
                                    placeholder="Enter the Password"
                                    onChange={handleChange}
                                    value={password}
                                    autoFocus={true}
                                />
                            )}
                        </div>
                        <div class="flex items-center justify-between">
                            <button
                                class="rounded-md w-full p-4 bg-zinc-900 text-zinc-50 text-sm font-Syne font-semibold transition hover:bg-zinc-700 duration-300 ease-out"
                                type="button"
                                onClick={handleOnClick}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="flex items-center justify-between">
                <button
                    class="rounded-lg w-full px-6 py-3 bg-emerald-900 text-stone-50 text-sm font-Syne font-semibold transition hover:bg-emerald-700 duration-300 ease-out"
                    type="button"
                    onClick={handleOnClick}
                >
                    Submit
                </button>
            </div>
        </div >
    );
}
