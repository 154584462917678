import { motion } from "framer-motion";
import {
    container,
    introductionFade,
    itemFade,
} from "../components/Animations";

export default function Intro() {
    return (
        <motion.div
            variants={introductionFade}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="container flex flex-col justify-center my-36 z-20"
        >
            <motion.div
                variants={itemFade}
                className="text-2xl md:text-3xl mb-2 text-zinc-900 font-Syne font-semibold md:w-3/4"
            >
                Hey, I'm Justin. I design products that promote <span className="  bg-indigo-100 text-indigo-800"><i>clarity</i></span> and feel <span className="  bg-indigo-100 text-indigo-800"><i>effortless</i></span>.
            </motion.div>
            <motion.div variants={itemFade} className="text-lg  text-zinc-900 mb-2">
                Specializing in visual, interaction, and information architecture
            </motion.div>
            {/* <motion.div variants={itemFade} className="text-base text-zinc-500">
                Specializing in visual, interaction, and information architecture
            </motion.div> */}
        </motion.div>
    );
}
